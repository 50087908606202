import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SlideShowContent from '../layouts/SlideShow';
import { useConference } from '../contexts/ConferenceContext';
import { useLanguage } from '../contexts/LanguageContext';

const SlideShowComponent = ({ openRegistrationModal }) => {
  const [heroContent, setHeroContent] = useState(null);
  const [subElements, setSubElements] = useState([]);
  const { conference, loading, error } = useConference();
  const { language } = useLanguage();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [heroResponse, subElementsResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/api/cms/hero-section/public/latest-active`),
          axios.get(`${process.env.REACT_APP_API_URL}/api/cms/hero-section-sub-element/public/get-all-active`)
        ]);

        const heroData = heroResponse.data || {};

        const formattedHeroContent = {
          ...heroData,
          bigHeadline: language === 'en' ? heroData.bigHeadlineEN || heroData.bigHeadline || '' : heroData.bigHeadline || '',
          subHeadline: language === 'en' ? heroData.subHeadlineEN || heroData.subHeadline || '' : heroData.subHeadline || '',
          scienceCares: language === 'en' ? heroData.scienceCaresEN || heroData.scienceCares || '' : heroData.scienceCares || '',
        };

        setHeroContent(formattedHeroContent);
        
        const subElementsData = subElementsResponse.data || [];
        const sortedSubElements = subElementsData.sort((a, b) => (a.id || 0) - (b.id || 0));
        
        const formattedSubElements = sortedSubElements.map(subElement => {
          const isHeartportPublic = (subElement.headline || '').toLowerCase() === 'heartport public';
          const isHeartsConference = (subElement.headline || '').toLowerCase() === 'hearts conference';
          
          return {
            title: language === 'en' ? subElement.headlineEN || subElement.headline || '' : subElement.headline || '',
            description: language === 'en' ? subElement.descriptionEN || subElement.description || '' : subElement.description || '',
            url: subElement.thumbnailUrl ? `${process.env.REACT_APP_API_URL}${subElement.thumbnailUrl}` : '',
            isConference: isHeartsConference,
            showComingSoon: !isHeartportPublic && !isHeartsConference && subElement.comingSoon,
            externalUrl: isHeartportPublic ? 'https://public.heartport.org/' : subElement.externalUrl || ''
          };
        });

        setSubElements(formattedSubElements);

      } catch (error) {
        console.error('Error fetching data:', error);
        setHeroContent(null);
        setSubElements([]);
      }
    };

    fetchData();
  }, [language]);

  if (loading) {
    return null;
  }

  if (error) {
    console.error("Error loading conference data:", error);
    return null;
  }

  if (!heroContent) {
    return null;
  }

  return (
    <SlideShowContent 
      heroContent={heroContent} 
      subElements={subElements} 
      conference={conference || {}}
      openRegistrationModal={openRegistrationModal}
    />
  );
};

export default SlideShowComponent;