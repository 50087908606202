import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import audio from '../assets/images/audio.png';
import { useLanguage } from '../contexts/LanguageContext';

const MainWrapper = styled.div`
  background: transparent;
  color: white;
`;

const HeroSection = styled.section`
  position: relative;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  @media (max-width: 768px) {
    height: auto;
    min-height: 40vh;
  }
`;

const HeroContent = styled.div`
  position: absolute;
  max-width: 30%;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
  z-index: 2;
  @media (max-width: 768px) {
    position: relative;
    max-width: 100%;
    top: auto;
    left: auto;
    transform: none;
    padding: 20px;
  }
`;

const HeroButton = styled.button`
  background-color: #47ABDB;
  text-decoration: none;
  height: 4.0rem;
  font-weight: bold;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1.8rem;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  max-width: 300px;
  text-align: center;
  margin-bottom: 0.1rem;

  &:hover {
    background-color: #0056b3;
  }
  @media (max-width: 1080px) {
    font-size: 1.4rem;;
  }
  @media (max-width: 768px) {
    font-size: 1.4rem;;
  }
`;

const ConferenceInfo = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
`;

const ConferenceTitle = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  @media (max-width: 768px) {
    font-size: 1rem;;
  }
`;

const ConferenceDetails = styled.p`
  font-size: 1.4rem;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const HeroMedia = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  @media (max-width: 768px) {
    position: relative;
    height: 40vh;
  }
`;

const MediaItem = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${props => props.active ? 1 : 0};
  transform: translateX(${props => props.position}%);
  transition: opacity 0.0s ease-in-out, transform 1.5s ease-in-out;

  img, video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const SubElementsSection = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

const SubElement = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 300px;
  cursor: ${props => props.isClickable ? 'pointer' : 'default'};
  background: #040A19;
  padding: 8px;
  ${props => props.isConference && `
    outline: 10px solid #90C4D9;
    outline-offset: -2px;
  `}
`;

const SubElementImageWrapper = styled.div`
  width: 100%;
  height: 70%;
  overflow: hidden;
`;

const SubElementImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  ${SubElement}:hover & {
    transform: scale(1.1);
  }
`;

const SubElementContent = styled.div`
  padding: 10px 2px;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ComingSoonBanner = styled.div`
  position: absolute;
  top: 30px;
  right: -50px;
  background-color: #47ABDB;
  color: white;
  padding: 5px 0;
  width: 200px;
  text-align: center;
  transform: rotate(45deg);
  font-size: 0.6em;
  font-weight: bold;
  z-index: 2;
  display: ${props => props.show ? 'block' : 'none'};
`;

const LinkTitle = styled.span`
  color: white;
  font-weight: bold;
  font-size: 1.4rem;
`;

const LinkDescription = styled.span`
  color: white;
  font-size: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AudioIcon = styled.img`
  width: 30px;
  height: 25px;
  cursor: ${props => props.clickable ? 'pointer' : 'default'};
  margin-top: 10px;
  opacity: ${props => props.visible ? 1 : 0};
  pointer-events: ${props => props.clickable ? 'auto' : 'none'};
  transition: opacity 0.3s ease;

  @media (max-width: 768px) {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 3;
    margin-top: 0;
  }
`;

const BigHeadline = styled.h1`
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const ColoredWord = styled.span`
  text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
  color: #48ABDB;
`;

const SubHeadline = styled.p`
  font-size: 1.4rem;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
  color: white;
  margin-bottom: 1.5rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ScienceCares = styled.h2`
  color: #48ABDB;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
  margin-bottom: 1rem;
  
`;

const ScienceCaresFirstWord = styled.span`
  font-size: 1.4rem;
  display: block;
  @media (max-width: 768px) {
      font-size: 1.4rem;
  }
`;

const ScienceCaresRest = styled.span`
  font-size: 3rem;
  display: block;
  @media (max-width: 768px) {
      font-size: 3rem;
  }
`;

const SlideShowContent = ({ heroContent = {}, subElements = [], conference = null, openRegistrationModal }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [fullscreenVideoIndex, setFullscreenVideoIndex] = useState(null);
  const [isMuted, setIsMuted] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL || '';
  const videoRefs = useRef([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { language } = useLanguage();
  const intl = useIntl();
  console.log("conference in slideshow: ", conference)

  const slideShowItems = heroContent?.slideShowItems?.filter(item => item && item.mediaType) || [];

  useEffect(() => {
    videoRefs.current = videoRefs.current.slice(0, slideShowItems.length);
  }, [slideShowItems]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const currentVideo = videoRefs.current[currentSlideIndex];
    if (currentVideo) {
      currentVideo.playsInline = true;
      currentVideo.muted = isMuted;
      currentVideo.autoplay = true;
      currentVideo.loop = true;
      
      currentVideo.play().catch(error => console.error('Error attempting to play', error));
    }
  }, [currentSlideIndex, isMuted]);

  useEffect(() => {
    videoRefs.current.forEach((video, index) => {
      if (video) {
        video.muted = index !== fullscreenVideoIndex;
      }
    });
  }, [fullscreenVideoIndex, currentSlideIndex]);

  useEffect(() => {
    if (slideShowItems.length > 1) {
      const interval = setInterval(() => {
        setIsTransitioning(true);
        setTimeout(() => {
          setCurrentSlideIndex((prevIndex) => {
            const newIndex = (prevIndex + 1) % slideShowItems.length;
            if (isMobile) {
              setIsMuted(true);
            }
            return newIndex;
          });
          setIsTransitioning(false);
        }, 1500);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [slideShowItems, isMobile]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      const newFullscreenState = !!document.fullscreenElement;
      setIsFullscreen(newFullscreenState);
      
      if (newFullscreenState) {
        setFullscreenVideoIndex(currentSlideIndex);
        setIsMuted(false);
      } else {
        setFullscreenVideoIndex(null);
        setIsMuted(true);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [currentSlideIndex]);

  const formatDate = (dateString) => {
    if (!dateString) {
      return '';
    }
    
    const date = new Date(dateString);
    
    if (isNaN(date.getTime())) {
      return '';
    }
    
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const getSlidePosition = (index) => {
    const totalSlides = slideShowItems.length;
    
    if (index === currentSlideIndex) return isTransitioning ? -100 : 0;
    
    const nextIndex = (currentSlideIndex + 1) % totalSlides;
    if (index === nextIndex) return isTransitioning ? 0 : 100;
    
    return 100;
  };

  const handleSubElementClick = (element) => {
    const isHeartportPublic = element.title.toLowerCase() === 'heartport public';
    const isHeartsConference = element.title.toLowerCase() === 'hearts conference' || element.title.toLowerCase() === 'hearts konferenz';
    
    if (element.externalUrl && (!element.showComingSoon || isHeartportPublic) && !isHeartsConference) {
      window.open(element.externalUrl, '_blank');
    }
  };

  const currentSlide = slideShowItems[currentSlideIndex] || {};
  const isCurrentSlideVideo = currentSlide.mediaType === 'VIDEO';

  const handleAudioToggle = () => {
    const currentVideo = videoRefs.current[currentSlideIndex];
    if (currentVideo) {
      if (isMobile) {
        setIsMuted(!isMuted);
      } else {
        if (!isFullscreen) {
          if (currentVideo.requestFullscreen) {
            currentVideo.requestFullscreen();
          }
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          }
        }
      }
    }
  };

  const renderAudioIcon = () => (
    <AudioIcon 
      src={audio} 
      alt={isMuted ? "Enable audio" : "Disable audio"} 
      onClick={isCurrentSlideVideo ? handleAudioToggle : undefined}
      visible={isCurrentSlideVideo}
      clickable={isCurrentSlideVideo}
    />
  );

  const renderBigHeadline = () => {
    const headline = language === 'en' ? heroContent.bigHeadlineEN : heroContent.bigHeadline;
    if (!headline) return null;

    const words = headline.split(' ');
    const firstWord = words[0];
    const restWords = words.slice(1).join(' ');

    return (
      <BigHeadline>
        <ColoredWord>{firstWord}</ColoredWord> {restWords}
      </BigHeadline>
    );
  };

  const renderSubHeadline = () => {
    const subHeadline = language === 'en' ? heroContent.subHeadlineEN : heroContent.subHeadline;
    if (!subHeadline) return null;

    return <SubHeadline>{subHeadline}</SubHeadline>;
  };

  const renderScienceCares = () => {
    const scienceCares = language === 'en' ? heroContent.scienceCaresEN : heroContent.scienceCares;
    if (!scienceCares) return null;

    const words = scienceCares.split(' ');
    const firstWord = words[0];
    const restWords = words.slice(1).join(' ');

    return (
      <ScienceCares>
        <ScienceCaresFirstWord>{firstWord}</ScienceCaresFirstWord>
        <ScienceCaresRest>{restWords}</ScienceCaresRest>
      </ScienceCares>
    );
  };

  const getContent = (defaultContent, enContent) => {
    return language === 'en' && enContent ? enContent : (defaultContent || '');
  };

  const renderHeroContent = () => {
    return (
      <>
        {renderBigHeadline()}
        {renderSubHeadline()}
        {renderScienceCares()}
        {conference && (
          <HeroButton onClick={() => openRegistrationModal(conference)}>
            <FormattedMessage id="hero.button" defaultMessage="Register" />
          </HeroButton>
        )}
        {conference && (
          <ConferenceInfo>
            <ConferenceTitle>
              <>{getContent(conference?.mainHeadline, conference?.mainHeadlineEN) || ''}</>
            </ConferenceTitle>
            <ConferenceDetails>
              {formatDate(conference.dateTime) && conference.location ? (
                  <>
                    {formatDate(conference.dateTime)} - {conference.location}
                  </>
                ) : (
                  <>
                    {formatDate(conference.dateTime)}
                    {conference.location}
                  </>
              )}
            </ConferenceDetails>
          </ConferenceInfo>
        )}
        {!isMobile && renderAudioIcon()}
      </>
    );
  };
  
  return (
    <MainWrapper>
      <HeroSection>
        <HeroMedia>
          {slideShowItems.map((slide, index) => (
            <MediaItem
              key={slide.id || index}
              active={index === currentSlideIndex || (isTransitioning && index === (currentSlideIndex + 1) % slideShowItems.length)}
              position={getSlidePosition(index)}
            >
              {slide.mediaType === 'VIDEO' ? (
                <video 
                  ref={el => videoRefs.current[index] = el}
                  playsInline
                  muted={index !== fullscreenVideoIndex}
                  autoPlay={index === currentSlideIndex}
                  loop
                  controls={isFullscreen && index === fullscreenVideoIndex}
                >
                  <source src={`${API_URL}${slide.mediaUrl}`} type="video/mp4" />
                </video>
              ) : (
                <img src={`${API_URL}${slide.mediaUrl}`} alt={heroContent.title || ''} />
              )}
            </MediaItem>
          ))}
          {isMobile && renderAudioIcon()}
        </HeroMedia>
        <HeroContent>
          {renderHeroContent()}
        </HeroContent>
      </HeroSection>
      <SubElementsSection>
        {subElements.map((element, index) => {
          const isHeartportPublic = element.title.toLowerCase() === 'heartport public';
          const isHeartsConference = element.title.toLowerCase() === 'hearts conference' || element.title.toLowerCase() === 'hearts konferenz';
          
          return (
            <SubElement 
              key={index} 
              isConference={isHeartsConference}
              isClickable={(!element.showComingSoon || isHeartportPublic) && !isHeartsConference && element.externalUrl}
              onClick={() => handleSubElementClick(element)}
            >
              <SubElementImageWrapper>
                <SubElementImage src={element.url} alt={element.title} />
              </SubElementImageWrapper>
              <SubElementContent>
                <LinkTitle>{element.title}</LinkTitle>
                <LinkDescription>{element.description}</LinkDescription>
              </SubElementContent>
              <ComingSoonBanner show={!isHeartportPublic && !isHeartsConference && element.showComingSoon}>
                COMING SOON
              </ComingSoonBanner>
            </SubElement>
          );
        })}
      </SubElementsSection>
    </MainWrapper>
  );
};

export default SlideShowContent;
